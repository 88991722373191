import { useContext, useState, useEffect, useMemo } from 'react';
import { isCarLine } from '@oneaudi/feature-app-utils';
import { CARLINE_QUERY } from '../queries/carlineQuery';
import { Context } from '../Context';
import {
  CarByCodeForStageFaQuery,
  CarlineForStageFaQuery,
  CarlineStructureCarline,
} from '../../generated/onegraph';
import { CAR_BY_CODE_QUERY } from '../queries/carByCodeQuery';

export type OnegraphCarlineDataType = Array<CarlineForStageFaQuery | undefined>;
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export type OnegraphAudicodeDataType = Array<CarByCodeForStageFaQuery | undefined>;
// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
export type OnegraphDataType = (CarlineStructureCarline | CarByCodeForStageFaQuery | undefined)[];

export const useDataFromOneGraph = (Ids: string[] | undefined): OnegraphDataType => {
  const { localeService, oneGraphService } = useContext(Context);
  const oneGraphClient = oneGraphService?.getApolloClient();

  const [oneGraphData, setOneGraphData] = useState<OnegraphDataType>([]);

  const consumerId = `${__FEATURE_APP_NAME__}@${__FEATURE_APP_VERSION__}`;
  const context = { consumerId };

  // Memoize CarlineIdentifierInput to prevent infinite loop
  const CarlineIdentifierInputs = useMemo(() => {
    return Ids?.map((id) => {
      const isCarline = isCarLine(id);
      return {
        idType: isCarline ? 'carline' : 'audicode',
        query: isCarline ? CARLINE_QUERY : CAR_BY_CODE_QUERY,
        variables: isCarline
          ? {
              identifier: {
                brand: localeService?.brand ?? 'A',
                language: localeService?.language ?? '',
                country: localeService?.countryCode ?? '',
                id: id ?? '',
              },
            }
          : { code: id },
      };
    });
  }, [localeService?.countryCode, Ids]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!CarlineIdentifierInputs) return;

        const responses = await Promise.all(
          CarlineIdentifierInputs?.map(async (input) => {
            const response = await oneGraphClient?.query<
              CarlineForStageFaQuery | CarByCodeForStageFaQuery
            >({
              context,
              variables: { ...input.variables },
              query: input.query,
            });
            return response?.data;
          }),
        );
        if (responses !== undefined) {
          setOneGraphData(responses);
        }
      } catch (error) {
        console.error('Error! carlines fetching data for fa-stage', error);
      }
    };

    if (oneGraphClient) {
      fetchData().catch((err) => {
        console.error('[fa-stage] Server Error fetching from GraphQL:', err);
      });
    }
  }, [oneGraphClient, CarlineIdentifierInputs]);

  return oneGraphData;
};
