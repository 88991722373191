import React from 'react';
import { audiDarkTheme, audiLightTheme, Theme, ThemeProvider } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { ConsumptionAndEmissions } from './ConsumptionAndEmissions';
import { Disclaimers } from './Disclaimers';
import { AppearanceProps, ContentPosition, CTAsTheme, Theme as ThemeProps } from '../../../types';
import { OnegraphDataType } from '../../hooks/useDataFromOneGraph';
import { CarByCodeForStageFaQuery } from '../../../generated/onegraph';
import { getcNeDataForCarByCode, isCarlineData } from './extractConsumptionAndEmission';

export const StageLegalWrapper = styled.div<{
  appearance: AppearanceProps;
  isModelPage?: boolean;
}>`
  width: auto;
  overflow: hidden;
  max-width: 1920px;
  position: relative;
  z-index: 2;
  pointer-events: auto;
  background-color: ${({ appearance, isModelPage }) => {
    if (isModelPage) {
      return `transparent`;
    }
    switch (appearance.backgroundDisclaimersCTAs) {
      case CTAsTheme.Light:
        return `${audiLightTheme.colors.background.level[0]};`;
      case CTAsTheme.Dark:
        return `${audiDarkTheme.colors.background.level[0]};`;
      case CTAsTheme.Media:
        return appearance.theme === ThemeProps.Light
          ? `${audiLightTheme.colors.background.level[0]};`
          : `${audiDarkTheme.colors.background.level[0]};`;
      default:
        return `${audiLightTheme.colors.background.level[0]};`;
    }
  }};
  color: ${({ appearance, isModelPage }) => {
    if (isModelPage) {
      return appearance.theme === ThemeProps.Light
        ? `${audiLightTheme.colors.ui.secondary};`
        : `${audiDarkTheme.colors.ui.secondary};`;
    }
    switch (appearance.backgroundDisclaimersCTAs) {
      case CTAsTheme.Light:
        return `${audiLightTheme.colors.ui.secondary};`;
      case CTAsTheme.Dark:
        return `${audiDarkTheme.colors.ui.secondary};`;
      case CTAsTheme.Media:
        return appearance.theme === ThemeProps.Light
          ? `${audiLightTheme.colors.ui.secondary};`
          : `${audiDarkTheme.colors.ui.secondary};`;
      default:
        return `${audiLightTheme.colors.ui.secondary};`;
    }
  }};
  @media (min-width: ${() => audiDarkTheme.breakpoints?.m}px) {
    ${({ appearance, isModelPage }) => {
      if (
        appearance.backgroundDisclaimersCTAs === CTAsTheme.Media &&
        appearance.contentPosition !== ContentPosition.BOTTOM_LEFT &&
        !isModelPage
      ) {
        return `
        background: transparent;
        position: absolute;
        bottom: 0;
        pointer-events: auto;
      `;
      }
      return `background: transparent;
      pointer-events: auto;`;
    }}
  }
  padding: ${({ theme }: { theme: Theme }) =>
    `var(${theme.responsive?.spacing.l}) var(${theme.responsive?.spacing.pageMargin}) var(${theme.responsive?.spacing.l}) var(${theme.responsive?.spacing.pageMargin});`};
`;

interface StageLegalProps {
  oneGraphData?: OnegraphDataType | null;
  disclaimers?: string[];
  appearance: AppearanceProps;
  isModelPage?: boolean;
}

const StageLegal: React.FC<StageLegalProps> = ({
  oneGraphData,
  disclaimers,
  appearance,
  isModelPage,
}) => {
  // Checks if consumptionAndEmissions and disclaimers are not empty string arrays
  const hasContent = Boolean(
    (oneGraphData?.length && oneGraphData.some((element) => element !== null)) ||
      disclaimers?.length,
  );
  const { backgroundDisclaimersCTAs } = appearance;

  if (!hasContent) return null;
  const chooseTheme = backgroundDisclaimersCTAs === CTAsTheme.Dark && !isModelPage;
  return (
    <ThemeProvider theme={chooseTheme ? audiDarkTheme : audiLightTheme}>
      <StageLegalWrapper
        appearance={appearance}
        isModelPage={isModelPage}
        data-testid="consumption-emissions-data"
      >
        {oneGraphData &&
          oneGraphData?.map((data) => {
            const isCarline = isCarlineData(data);
            const cNeDataForCarByCode = getcNeDataForCarByCode(data as CarByCodeForStageFaQuery);

            return (
              <ConsumptionAndEmissions
                consumptionAndEmission={
                  isCarline ? data?.carline?.consumptionAndEmission.summary : cNeDataForCarByCode
                }
              />
            );
          })}

        <Disclaimers disclaimers={disclaimers} />
      </StageLegalWrapper>
    </ThemeProvider>
  );
};

export default StageLegal;
