import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Text, Theme } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import { Context } from '../../Context';
import { AppearanceProps } from '../../../types';

import { fetchI18n, I18nTexts } from '../../utils/fetchi18n';
import { OnegraphDataType } from '../../hooks/useDataFromOneGraph';
import { isCarlineData } from '../stage-legal/extractConsumptionAndEmission';
import { CarlineForStageFaQuery } from '../../../generated/onegraph';
import { ContentContext } from '../../ContentContext';

const StyledContainer = styled.div`
  width: auto;
  pointer-events: auto;
  padding-top: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.l});`};

  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.m}px) {
    padding-bottom: ${({ theme }: { theme: Theme }) => `var(${theme.responsive?.spacing.xs});`};
  }
`;
interface StagePricesWrap {
  appearance?: AppearanceProps;
}
const StagePricesWrap = styled.div<StagePricesWrap>``;

interface StagePricesProps {
  oneGraphData?: OnegraphDataType | null;
}

const StagePrices: React.FC<StagePricesProps> = ({ oneGraphData }) => {
  const { i18nService, loggerService: logger, numberFormatterService } = useContext(Context);
  // @ts-expect-error initial type may be null
  const [i18nTexts, setI18nTexts] = useState<I18nTexts>(null);
  const { content } = React.useContext(ContentContext);
  const { prices: pricesContent, priceFootnotes } = content;
  const { totalPrice: showTotalPrice } = pricesContent;

  useEffect(() => {
    // no need to fetch i18n texts more than once
    if (i18nTexts) return;
    // Create an async function to fetch data
    const getI18nTexts = async () => {
      try {
        // @ts-expect-error logger type may be undefined
        const data = await fetchI18n({ i18nService, logger });
        setI18nTexts(data);
      } catch (error) {
        logger?.error('Error fetching i18n texts:', error);
      }
    };

    // Call the async function and catch any unhandled errors
    getI18nTexts().catch((error) => {
      logger?.error('Unhandled error:', error);
    });
  }, []);

  const prices = useMemo(() => {
    return (
      oneGraphData?.find((data) => {
        const isCarline = isCarlineData(data); // Check if the data is carline
        return isCarline && data?.carline?.prices; // Return prices if it's carline data
      }) as CarlineForStageFaQuery
    )?.carline?.prices;
  }, [oneGraphData]);

  let totalPrice;

  if (numberFormatterService?.formatCurrency) {
    if (prices?.minPrice?.value && showTotalPrice) {
      // Format the price
      const formattedPrice = numberFormatterService
        .formatCurrency(prices.minPrice.value.toString())
        .toString();
      // Format footnotes (default to an empty string if no footnotes exist)
      const formattedFootnotes =
        priceFootnotes?.map((footnote) => `{ft_${footnote}}`).join(', ') || '';
      const totalPriceText = i18nTexts?.totalPriceText.replace(
        // eslint-disable-next-line no-template-curly-in-string
        '${value}',
        `${formattedPrice}${formattedFootnotes}`,
      );
      totalPrice = renderTextWithFootnotesReferencesV2(totalPriceText);
    }
  }

  return (
    <StyledContainer data-testid="stage-prices">
      <Text variant="copy1" spaceStackStart="xxs">
        {totalPrice}
      </Text>
    </StyledContainer>
  );
};
export default StagePrices;
