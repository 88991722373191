import type { Logger } from '@feature-hub/core';
import type { I18NServiceV1 } from '@oneaudi/i18n-service';

export interface FetchI18nOptions {
  readonly i18nService: I18NServiceV1;
  readonly logger: Logger;
}

export type I18nTexts = {
  [Key in (typeof i18nKeys)[number]]: string;
};

const i18nKeyScope = 'fa.stage';

const i18nKeys = ['monthlyRateText', 'totalPriceText'] as const;

export async function fetchI18n({ i18nService, logger }: FetchI18nOptions): Promise<I18nTexts> {
  return new Promise<I18nTexts>((resolve) => {
    i18nService.setScopes(['fa.stage']);
    i18nService
      .registerCallback((messages) => {
        resolve(createI18nTexts(messages.default));
      })
      .catch((error) => {
        logger.error(error);

        resolve(createI18nTexts());
      });
  });
}

function createI18nTexts(data: Record<string, string> = {}): I18nTexts {
  const i18nTexts: Partial<I18nTexts> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key of i18nKeys) {
    const fullKey = `${i18nKeyScope}.${key}`;
    i18nTexts[key] = data[fullKey] ?? fullKey;
  }

  return i18nTexts as I18nTexts;
}
