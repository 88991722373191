import React, { memo, useRef } from 'react';
import { Text } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';
import styled from 'styled-components';
import type { Theme } from '@audi/audi-ui-react';
import { sanitizeHtml, sanitizeText } from '../../utils/textUtils';

export const ClickableElement = styled.span`
  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.m}px) {
    width: fit-content;
  }
  width: 100%;
  pointer-events: auto; // Make the element clickable
`;
export interface TextProps {
  readonly text: string;
}

const RichTextAnchor = memo(function RichTextAnchor({ text, ...inherited }: TextProps) {
  const anchorTag = text.includes('</a>') || text.includes('</u>');
  function convertRichText(richText: string) {
    if (typeof window !== 'undefined' && anchorTag) {
      const sanitizedHtml = sanitizeHtml(richText);
      return <span id="anchors-allowed" dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
    }
    const footnoteReference = renderTextWithFootnotesReferencesV2(sanitizeText(richText));
    return footnoteReference;
  }

  const textRef = useRef<HTMLSpanElement | null>(null);

  return (
    <ClickableElement ref={textRef}>
      <Text {...inherited}>{convertRichText(text)}</Text>
    </ClickableElement>
  );
});

const RichText = memo(function RichText({ text, ...inherited }: TextProps) {
  const convertRichText = (richText: string) => {
    const sanitizedText = sanitizeText(richText);
    return renderTextWithFootnotesReferencesV2(sanitizedText);
  };

  const textRef = useRef<HTMLSpanElement | null>(null);

  return (
    <ClickableElement ref={textRef}>
      <Text {...inherited}>{convertRichText(text)}</Text>
    </ClickableElement>
  );
});
export { RichTextAnchor, RichText };
