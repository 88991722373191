/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { Text } from '@audi/audi-ui-react';
import {
  cleanupEmissionConsumptionMarkup,
  renderTextWithFootnotesReferencesV2,
} from '@oneaudi/feature-app-utils';
import styled from 'styled-components';
import { CarlineConsumptionAndEmissionSummary, Maybe } from '../../../generated/onegraph';

export interface ConsumptionAndEmissionsProps {
  consumptionAndEmission: CarlineConsumptionAndEmissionSummary | null | undefined;
}
const StyledText = styled(Text)`
  color: inherit;
`;

const addFootnotesAndCleanup = (text: Maybe<string> | undefined) => {
  if (!text) return '';

  return renderTextWithFootnotesReferencesV2(cleanupEmissionConsumptionMarkup(text));
};

export const ConsumptionAndEmissions: React.FC<ConsumptionAndEmissionsProps> = ({
  consumptionAndEmission,
}) => {
  const consumption = addFootnotesAndCleanup(consumptionAndEmission?.consumption);
  const emissionCO2 = addFootnotesAndCleanup(consumptionAndEmission?.emissionCO2);
  const co2Class = addFootnotesAndCleanup(consumptionAndEmission?.co2Class);
  const dischargedConsumption = addFootnotesAndCleanup(
    consumptionAndEmission?.dischargedConsumption,
  );
  const dischargedCO2Class = addFootnotesAndCleanup(consumptionAndEmission?.dischargedCO2Class);

  return (
    <StyledText variant="copy2" spaceStackEnd="xs">
      {consumption}
      {emissionCO2 && <span>; {emissionCO2}</span>}
      {co2Class && <span>; {co2Class}</span>}
      {dischargedConsumption && <span>; {dischargedConsumption}</span>}
      {dischargedCO2Class && <span>; {dischargedCO2Class}</span>}
    </StyledText>
  );
};

ConsumptionAndEmissions.displayName = 'ConsumptionAndEmissions';
