import * as React from 'react';

import styled, { createGlobalStyle } from 'styled-components';
import { ThemeProvider, audiLightTheme, audiDarkTheme } from '@audi/audi-ui-react';

import { Theme, AssetTypeEnum, StageProps, Mode, CTAsTheme, ContentPosition } from '../../../types';

import StageLegal from '../stage-legal/StageLegal';
import StageButtons from '../stage-content/StageButtons';
import { useTracking } from '../../hooks/useTracking';

import StageVideo from '../stage-video/StageVideo';
import StageImage from '../stage-image/StageImage';
import StageShader from '../stage-shader/StageShader';
import StageContent from '../stage-content/StageContent';
import { useIsMobile } from '../../hooks/useIsMobile';
import { useDataFromOneGraph } from '../../hooks/useDataFromOneGraph';
import { ContentContext } from '../../ContentContext';

// this is needed to combat the infitine image height increasing problem in universal editor
const GlobalStyle = createGlobalStyle`
  html.adobe-ue-edit .mediaItem {
    height: 800px;
  }
    #fa-editorial-stage-video_fa-video-player-videoAppMuteButtonWrapper {
      z-index: 5;
    }
`;

const StageContainerWrap = styled.div<{ theme: Theme }>`
  background-color: ${({ theme }) => (theme === Theme.Light ? 'white' : 'black')};
  margin: 0;
  position: relative;
`;

const Block = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1920px;

  & a.audi-j-footnote-reference:hover {
    color: inherit;
  }
`;
export type StageContainerContent = StageProps;

const StageContainer: React.FC = () => {
  const { content, isHeadless } = React.useContext(ContentContext);
  const {
    asset,
    appearance,
    text,
    buttons,
    consumptionAndEmissions,
    disclaimers,
    prices,
    appearancemodelPage,
  } = content;

  const showPrices = isHeadless && (prices?.totalPrice || prices?.monthlyRate);
  const isModelPage = isHeadless && (showPrices || appearancemodelPage);
  const {
    mode = isModelPage ? Mode.LARGE : Mode.SMALL,
    theme,
    backgroundDisclaimersCTAs,
  } = appearance;
  const ref = React.useRef(null);
  const isMobile = useIsMobile();
  const tracking = useTracking(ref, content, {
    implementer: 37,
  });
  const oneGraphData = useDataFromOneGraph(consumptionAndEmissions && consumptionAndEmissions);

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  const backgroundTheme = (() => {
    if (backgroundDisclaimersCTAs !== CTAsTheme.Media && !isModelPage) {
      return backgroundDisclaimersCTAs;
    }
    return theme;
  })();

  const isLegalVisible = (() => {
    if (appearance.contentPosition !== ContentPosition.BOTTOM_LEFT && !isModelPage) {
      return true;
    }
    if (isMobile || (appearance.backgroundDisclaimersCTAs !== CTAsTheme.Media && !isModelPage)) {
      return true;
    }
    return false;
  })();

  return (
    <ThemeProvider theme={theme === Theme.Light ? audiLightTheme : audiDarkTheme}>
      <GlobalStyle />
      <StageContainerWrap ref={ref} theme={backgroundTheme}>
        {asset ? (
          <Block>
            {asset.assetType === AssetTypeEnum.Video && (
              <StageVideo
                mode={isModelPage ? Mode.LARGE : mode}
                asset={asset}
                data-testid="stage-video"
              />
            )}
            {asset.assetType === AssetTypeEnum.Image && (
              <StageImage
                mode={isModelPage ? Mode.LARGE : mode}
                asset={asset}
                data-testid="stage-image"
              />
            )}
            <StageShader isModelPage={isModelPage} appearance={appearance}>
              <StageContent
                appearance={appearance}
                text={text}
                buttons={isMobile && mode === Mode.SMALL && !isModelPage ? undefined : buttons}
                tracking={tracking}
                isMobile={isMobile}
                oneGraphData={oneGraphData}
                disclaimers={disclaimers}
                showPrices={showPrices}
                isModelPage={isModelPage}
              />
            </StageShader>
          </Block>
        ) : null}
        <Block>
          {isMobile && mode === Mode.SMALL && !isModelPage && (
            <StageButtons
              buttons={buttons}
              tracking={tracking}
              isMobile={isMobile}
              data-testid="stage-buttons"
            />
          )}
          {isLegalVisible && (
            <StageLegal
              oneGraphData={oneGraphData}
              disclaimers={disclaimers}
              appearance={appearance}
              isModelPage={isModelPage}
              data-testid="stage-legal"
            />
          )}
        </Block>
      </StageContainerWrap>
    </ThemeProvider>
  );
};

export default StageContainer;
