import { InvalidContentError, isContentFragment } from '@oneaudi/falcon-tools';
import { CTAsTheme, FalconContent, StageProps, StagePropsFACE, Theme } from '../../types';

export const transformCmsDataToProps = (content: StagePropsFACE | FalconContent): StageProps => {
  if (!content || Object.keys(content).length === 0) {
    // eslint-disable-next-line
    console.warn('No Editor content found for Stage');

    return {} as StageProps;
  }

  if (content.__type !== 'aem-headless') {
    const {
      asset,
      appearance,
      text,
      buttons,
      consumptionAndEmissions,
      priceFootnotes,
      prices,
      disclaimers,
      appearancemodelPage,
    } = content;
    return {
      asset,
      appearance: {
        ...appearance,
        theme: appearance.theme || Theme.Dark,
        backgroundDisclaimersCTAs: appearance.backgroundDisclaimersCTAs || CTAsTheme.Dark,
      },
      text,
      buttons,
      consumptionAndEmissions,
      priceFootnotes,
      prices,
      disclaimers,
      appearancemodelPage,
    };
  }

  return convertFalconContentToStageProps(content);
};

function isFalconContent(content: unknown): content is FalconContent {
  return Boolean(isContentFragment(content));
}

const convertFalconContentToStageProps = (content: FalconContent): StageProps => {
  if (!isFalconContent(content)) {
    throw new InvalidContentError('Content is invalid.');
  }

  const {
    appearanceTheme,
    appearanceBackgroundDisclaimersCTAs,
    appearanceEnableShader,
    appearanceContentPosition,
    appearanceMode,
    asset,
    firstButtonLabel,
    firstButtonUrl,
    firstButtonAriaLabel,
    firstButtonOpenInTabOrLayer,
    firstChatBotId,
    secondButtonLabel,
    secondButtonUrl,
    secondButtonAriaLabel,
    secondButtonOpenInTabOrLayer,
    secondChatBotId,
    consumptionAndEmissions,
    priceFootnotes,
    disclaimers,
    totalPrice,
    textHeadline,
    textHeadingTag,
    textSubline,
    appearancemodelPage,
  } = content.fields;

  return {
    appearance: {
      theme: appearanceTheme || Theme.Dark,
      backgroundDisclaimersCTAs: appearanceBackgroundDisclaimersCTAs || CTAsTheme.Dark,
      enableShader: appearanceEnableShader,
      contentPosition: appearanceContentPosition,
      mode: appearanceMode,
    },
    asset: {
      assetType: asset?.fields?.assetType,
      alt: asset?.fields?.assetAlt,
      landscape: {
        assetValue: asset?.fields?.assetLandscape?.path,
      },
      portrait: {
        assetValue: asset?.fields?.assetPortrait?.path,
      },
      poster: {
        assetValue: asset?.fields?.assetPoster?.path,
      },
      autoPlay: asset?.fields?.assetAutoPlay,
      loop: asset?.fields?.assetLoop,
      muted: asset?.fields?.assetMuted,
      controls: asset?.fields?.assetControls,
      preserveVideoWidth: asset?.fields?.assetPreserveVideoWidth,
    },
    buttons: [
      {
        label: firstButtonLabel,
        url: firstButtonUrl,
        ariaLabel: firstButtonAriaLabel,
        openInTabOrLayer: firstButtonOpenInTabOrLayer,
        chatBotId: firstChatBotId,
      },
      {
        label: secondButtonLabel,
        url: secondButtonUrl,
        ariaLabel: secondButtonAriaLabel,
        openInTabOrLayer: secondButtonOpenInTabOrLayer,
        chatBotId: secondChatBotId,
      },
    ],
    consumptionAndEmissions,
    priceFootnotes,
    prices: {
      totalPrice,
    },
    disclaimers,
    text: {
      headline: textHeadline,
      headingTag: textHeadingTag,
      subline: textSubline,
    },
    appearancemodelPage,
  } as StageProps;
};
