import React from 'react';
import { Theme } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { UeElement } from '@oneaudi/falcon-tools';
import {
  AppearanceProps,
  ContentPosition,
  CtaButton,
  CTAsTheme,
  TexContentProps,
} from '../../../types';
import StageButtons, { ResponsiveButtonsWrapper } from './StageButtons';
import { RichText, RichTextAnchor } from './RichText';
import { Tracking } from '../../hooks/useTracking';
import StageLegal from '../stage-legal/StageLegal';
import { OnegraphDataType } from '../../hooks/useDataFromOneGraph';
import StagePrices from '../stage-prices/StagePrices';

const StyledContainer = styled.div`
  width: inherit;
`;

interface StageContentWrapProps {
  contentPosition?: ContentPosition;
}

const StageContentWrap = styled.div<StageContentWrapProps>`
  width: auto;
  overflow: hidden;
  max-width: 1920px;
  padding: 0 4%;
  padding: ${({ theme }: { theme: Theme }) => {
    return `0 var(${theme.responsive?.spacing.pageMargin}) var(${theme.responsive?.spacing.xl}) var(${theme.responsive?.spacing.pageMargin});`;
  }};
  position: relative;
  z-index: 4 !important;
  white-space: pre-wrap;
  span#anchors-allowed > a {
    ${({ theme }: { theme: Theme }) => `
    all: unset;
    color: var(${theme.colors.ui.secondary});
    text-decoration: none !important;
    font-stretch: var(${theme.responsive.typography.order4.fontStretch});
    font-family: var(${theme.responsive.typography.order4.fontFamily});
    font-size: var(${theme.responsive.typography.order4.fontSize});
    line-height: var(${theme.responsive.typography.order4.lineHeight});
    cursor: pointer !important;`};
    outline: none;
  }
  #anchors-allowed {
    ${({ theme }: { theme: Theme }) => `
    color: var(${theme.colors.ui.secondary});
    font-stretch: var(${theme.responsive.typography.order4.fontStretch});
    font-family: var(${theme.responsive.typography.order4.fontFamily});
    font-size: var(${theme.responsive.typography.order4.fontSize});
    line-height: var(${theme.responsive.typography.order4.lineHeight});
`}
  }

  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.m}px) {
    text-align: ${({ contentPosition }) => contentPosition === ContentPosition.CENTER && 'center;'};
  }
  @media (max-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.m}px) {
    padding: ${({ theme }: { theme: Theme }) => {
      return `0 var(${theme.responsive?.spacing.pageMargin}) var(${theme.responsive?.spacing.l}) var(${theme.responsive?.spacing.pageMargin});`;
    }};
  }

  ${ResponsiveButtonsWrapper} {
    padding: 0px;
  }
`;

interface StageContentProps {
  text?: TexContentProps;
  buttons?: CtaButton[];
  appearance: AppearanceProps;
  tracking: Tracking;
  isMobile?: boolean;
  oneGraphData?: OnegraphDataType;
  disclaimers?: string[];
  isModelPage?: boolean;
  showPrices?: boolean;
}

const StageContent: React.FC<StageContentProps> = ({
  text,
  buttons,
  appearance,
  tracking,
  isMobile,
  oneGraphData,
  disclaimers,
  isModelPage,
  showPrices,
}) => {
  const { headline, headingTag, subline } = text || {};

  const showSubline = subline && !showPrices;
  const isLegalBottomLeft =
    (appearance.backgroundDisclaimersCTAs === CTAsTheme.Media &&
      !isMobile &&
      appearance.contentPosition === ContentPosition.BOTTOM_LEFT) ||
    (isModelPage && !isMobile);

  return (
    <StyledContainer data-testid="stage-content">
      {/* delete padding bottom if mode large and bottom left */}
      <StageContentWrap
        data-testid="headline-subline-buttons-data"
        contentPosition={isModelPage ? ContentPosition.BOTTOM_LEFT : appearance.contentPosition}
      >
        {headline && (
          <UeElement type="text" property="textHeadline" label="Headline">
            {(ueProps) => (
              <RichText
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                as={headingTag || 'h1'}
                spaceStackEnd="xs"
                spaceStackStart="xl"
                variant={isModelPage ? 'display2' : 'order1'}
                text={headline}
                data-testid="headline-data"
                {...ueProps}
              />
            )}
          </UeElement>
        )}
        {showSubline && (
          <UeElement type="text" property="textSubline" label="Subline">
            {(ueProps) => (
              <RichTextAnchor
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                as="span"
                id="anchors-allowed"
                spaceStackEnd="l"
                text={subline}
                data-testid="subline-data"
                {...ueProps}
              />
            )}
          </UeElement>
        )}
        {showPrices && <StagePrices oneGraphData={oneGraphData} />}
        {!isMobile && <StageButtons buttons={buttons} tracking={tracking} />}
      </StageContentWrap>
      {isMobile && (
        <StageButtons
          buttons={buttons}
          tracking={tracking}
          isMobile={isMobile}
          data-testid="stage-buttons"
        />
      )}
      {isLegalBottomLeft && (
        <StageLegal
          oneGraphData={oneGraphData}
          disclaimers={disclaimers}
          appearance={appearance}
          isModelPage={isModelPage}
        />
      )}
    </StyledContainer>
  );
};

export default StageContent;
