/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';

const DisclaimersContainer = styled.div`
  text-align: left;
  p,
  span {
    color: inherit;
  }
`;

export interface DisclaimersProps {
  disclaimers?: string[];
}

export const Disclaimers: React.FC<DisclaimersProps> = ({ disclaimers }) => {
  if (!disclaimers || !disclaimers.length) return null;

  return (
    <DisclaimersContainer data-testid="disclaimers">
      {disclaimers?.map((disclaimer, i) => (
        <Text spaceStackEnd="xs" spaceStackStart="xs" key={`disclaimer${i}`} variant="copy2">
          {renderTextWithFootnotesReferencesV2(disclaimer)}
        </Text>
      ))}
    </DisclaimersContainer>
  );
};

Disclaimers.displayName = 'Disclaimers';
